import {AbstractComponent, ComponentLoader} from "ui-base/src/AbstractComponent";

declare let $: any;
export class FaviconLink extends AbstractComponent
{
    public static selector: string = 'favicon-link';
    protected matcher;

    public init(){
        this.matcher = window.matchMedia('(prefers-color-scheme: dark)');
        if(this.matcher.matches)
        {
            this.matcher.addEventListener('change', this.switchMode.bind(this));
            this.switchMode(this.matcher);
        }
    }

    protected switchMode(e)
    {
        let element = this.getComponentElement();

        if (e.matches) {
            element.attr('href', element.attr('href-darkmode'));
        } else {
            element.attr('href', element.attr('href-default'));
        }
    }
}

new ComponentLoader(FaviconLink);
